import React, { useState } from "react";
import "./QuestionTypeComponent.scss";
import { useContext, useEffect } from "react";
import Context from "../../../context/Context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import QuizBasicComponent from "../quizBasicComponent/QuizBasicComponent";
import CustomModalComponent from "../../../shared/customModalComponent/CustomModalComponent";
import MobileTimerComponent from "../mobileTimerComponent/MobileTimerComponent";
import QuizDragArrangeCompWrapper from "./quizDragArrangeComponent/QuizDragArrangeCompWrapper";
import QuizMTFComponent from "./quizMTFComponent/QuizMTFComponent";
import QuizMTFCompWrapper from "./quizMTFComponent/QuizMTFCompWrapper";

const QuestionTypeComponent = ({
  question,
  setSelectedAnswer,
  selectedAnswer,
  questionCount,
  noOfQuestions,
}) => {
  const { socket, maxTime, timerProgress, setTimerProgress, showAnswerPage, leaderboardData, questionData } = useContext(Context);

  const [lockedModalOpen, setLockedModalOpen] = useState(false);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log(question);
  // console.log(question?.questionType);

  useEffect(() => {
    if (questionData.questionType === "NQS")
      return //dont set timer for NQS slide
    const timer = setInterval(() => {
      setTimerProgress((prevProgress) => {
        if (prevProgress >= maxTime) {
          clearInterval(timer)
          sendTimeUp()
          return maxTime
        } else{
          return prevProgress + 1
        }
      })
    }, 1000);
    return (() => {
      clearInterval(timer)
      setTimerProgress(0);
    });
  }, [questionData]);

  useEffect(() => {
    if (selectedAnswer) {
      setLockedModalOpen(true)
    }
    if (showAnswerPage) {
      setLockedModalOpen(false)
    }
  }, [selectedAnswer, showAnswerPage])

  const sendTimeUp = () => {
    if (sessionStorage.getItem("isHost")) {
      socket &&
        socket.send(
          JSON.stringify({
            action: "timeUp",
          })
        );
    }
  }

  useEffect(() => {
    document.getElementById("main").scrollTo(0, 0)
  }, [])

  return (
    <>

      <div className="questionType">
        {(question?.questionType === "DND") ?
          (<QuizDragArrangeCompWrapper
            question={question}
            setSelectedAnswer={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
            questionCount={questionCount}
            noOfQuestions={noOfQuestions}
          />) : ((question?.questionType === "MTF") ?
            (<QuizMTFCompWrapper
              question={question}
              setSelectedAnswer={setSelectedAnswer}
              selectedAnswer={selectedAnswer}
              questionCount={questionCount}
              noOfQuestions={noOfQuestions}
            />) :
            (<QuizBasicComponent
              question={question}
              setSelectedAnswer={setSelectedAnswer}
              selectedAnswer={selectedAnswer}
              questionCount={questionCount}
              noOfQuestions={noOfQuestions}
            />)
          )
        }

      </div>

      <CustomModalComponent
        open={lockedModalOpen && leaderboardData?.noOfActivePlayers > 1}
        handleClose={() => { setLockedModalOpen(false) }}
      >
        <div className="modal_lockedAnswer">
          <h1 className="modal_lockedAnser__title">
            Answer Has
            <br />
            Been Locked
          </h1>
          <div className="modal_lockedAnswer__bottom">
            <MobileTimerComponent maximumTime={maxTime}
              progress={timerProgress}
              // displayValue={timerProgress}
              dialSize={isMobile ? "6rem" : "7rem"}
              textSize={isMobile ? "1.5rem" : "2rem"}
              dialColor="#FFF"
            />
            <p>
              Your answer has been recorded. Sit tight till
              <br />
              the timer runs out and others to answer to
              <br />
              know if you have aced it
            </p>
          </div>
        </div>
      </CustomModalComponent>
    </>
  );
};

export default QuestionTypeComponent;
