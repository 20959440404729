import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import React, { useContext} from "react";
import Context from "../../../context/Context";
import MobileTimerComponent from "../mobileTimerComponent/MobileTimerComponent";
import QuizNavBar from "../quizNavBarComponent/QuizNavBarComponent";
import "./QuizMobileFixedHeader.scss"
import LeaderboardList from "../../leaderboardList/LeaderboardList";

const QuizMobileFixedHeader = ({ questionCount, noOfQuestions }) => {
    const { leaderboardData, playerAnswerCount, playerCorrectAnswerCount, mobileLeaderBoardOpen, 
        timerProgress, maxTime, showAnswerPage, noOfCorrectAns, questionData } = useContext(Context);
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <div className={mobileLeaderBoardOpen ? "quizMobileFixedHeader quizMobileFixedHeader__fullheight" : "quizMobileFixedHeader"}>
            {isTablet && (
                <div className="quizMobileFixedHeader__container">
                    <div className="quizMobileFixedHeader__header">
                        <p className="quizMobileFixedHeader__questioncount">
                            {`Question ${questionCount} / ${noOfQuestions}`}
                        </p>
                            <p className="quizMobileFixedHeader__answeredcount">
                            {
                                leaderboardData?.playerStats?.length > 1 ? (
                                    showAnswerPage ?
                                        (`${playerCorrectAnswerCount} of ${leaderboardData?.noOfActivePlayers} Got it Right!`) :
                                        (`${playerAnswerCount} of ${leaderboardData?.noOfActivePlayers} Players Answered`)) :
                                        (`${noOfCorrectAns}/${questionData?.questionNumber} Correct Answers`)
                            }
                        </p>
                        <div
                            style={{
                                position: "absolute",
                                top: "1px",
                                right: "4vw",
                                paddingTop: isMobile ? "85px" : isTablet ? "85px" : "100px", //less than 600 padding is 85; more than 900 its 100; in between it is 92
                            }}
                        >
                            <MobileTimerComponent maximumTime={maxTime} progress={timerProgress} />{" "}
                        </div>
                    </div>
                    {isTablet && (
                        <QuizNavBar />
                    )}
                    {mobileLeaderBoardOpen && <LeaderboardList />}
                </div>
            )}
        </div>
    );
}
export default QuizMobileFixedHeader;