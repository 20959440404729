import React, { useContext } from "react";
import "./NameEmailComponent.scss";
import { Button, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import TitleComponent from "../../../shared/titleComponent/TitleComponent";
import { validateEmail } from "../../../shared/constants";
import Context from "../../../context/Context";
import axios from "axios";
import { BEURL, S3URL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import { isAtleastOneKeyEmpty } from "./../../../shared/constants";

const NameEmailComponent = () => {
  const navigate = useNavigate();
  let { qtestId } = useParams();
  qtestId = qtestId.trim().toLowerCase();
  const [additionalFieldsArray, setAdditionalFieldsArray] = useState([]);
  const [qtestName, setQtestName] = useState("");
  const [qtestImage, setQtestImage] = useState("");
  const [display, setDisplay] = useState("");
  const {
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
  } = useContext(Context);

  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  useEffect(()=>{
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.checkQtest;
    const data = {
      qtestId: qtestId
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status == "Active") {
          setQtestName(res.data.qtestName)
          setQtestImage(res.data.qtestImage)
          setAdditionalFieldsArray(res.data.additionalFields)
          setDisplay("QA")
        } else if (res.data.status == "Expired") {
          setDisplay("QE")
        } else if (res.data.status == "YetToStart") {
          setDisplay("QY")
        }
      })
      .catch((error) => {
        setAlert("error", error.response.data);
      })
      .finally(()=>{
        setLoadingDisplayFlag(false);
      });
  },[])

  return (
    <div className="nameEmail">
      {display=="QA" && <NameEmailSubComponent 
            qtestId={qtestId} 
            qtestName={qtestName}
            qtestImage={qtestImage}
            additionalFieldsArray={additionalFieldsArray} />}
      {display=="QE" && <QtestNotActiveComponent title="QTest Expired" message="Qtest has expired. Kindly contact the host to play this QTest." />}
      {display=="QY" && <QtestNotActiveComponent title="QTest is Yet To Start" message="Kindly visit after sometime." />}
    </div>
  );
};

const NameEmailSubComponent = ({qtestId, qtestName="", qtestImage=null, additionalFieldsArray}) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [additionalFields, setAdditionalFields] = useState({});
  const [buttonDisableFlag, setButtonDisableFlag] = useState(true);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const styledButton = {
    padding: isTablet ? "0.8rem 2rem" : "2.2rem 7rem",
    color: "#fff",
    marginTop: "auto",
    // fontSize:isMobile? "0.875rem" : "2.125rem",
    fontSize: "clamp(0.875rem, 2.36vw, 2.125rem)",
    letterSpacing: isMobile ? "0.5px" : "1px",
    // lineHeight: isMobile? "2.125rem" : "0.875rem",
    lineHeight: "clamp(0.875rem, 2.36vw, 2.125rem)",
    textAlign: "center",
    fontFamily: "CaustenR",
    fontWeight: "700",
    borderRadius: "10px",
    marginBottom: "2.5rem",
  };

  useEffect(() => {
    if(localStorage.getItem('isLoggedIn') && sessionStorage.getItem("sessionId")){
      setName(sessionStorage.getItem("name"))
      setEmail(sessionStorage.getItem("email"))
      setIsLoggedInUser(true)
    }

    // Initialize additionalFields Object from additionalFieldsArray
    let tempFieldsObj = {}
    additionalFieldsArray.forEach((fieldName)=>{
      tempFieldsObj[fieldName] = ""
    })
    setAdditionalFields(tempFieldsObj)
  },[])

  useEffect(() => {
    // Enable Proceed button when email and name fields are not empty and valid
    if (name.trim() == "" || !validateEmail(email) || isAtleastOneKeyEmpty(additionalFields)){
      setButtonDisableFlag(true);
    }
    else {
      setButtonDisableFlag(false)
    };
  }, [email, name, additionalFields]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleProceedClick = () => {
    if(isLoggedInUser){
      navigate("/qtest", {
        state: {
          isLoggedInUser: true,
          qtestId: qtestId,
          playerName: null,
          playerId: sessionStorage.getItem("sessionId"),
          additionalFields: additionalFields
        },
      });
    }
    else{
      navigate("/qtest", {
        state: {
          isLoggedInUser: false,
          qtestId: qtestId,
          playerName: name,
          playerId: email,
          additionalFields: additionalFields
        },
      });
    }
  };

  const additionalFieldsView = additionalFieldsArray.map((fieldName, index) => {
    return (
      <div className="nameEmail__details" key={index} >
        <label style={{ fontWeight: "700" }}>{fieldName.toUpperCase()}</label>
        <input
          name={fieldName}
          type="text"
          onChange={(event) => {
            setAdditionalFields((prevValue) => {
              prevValue[fieldName] = event.target.value
              return {
                ...prevValue,
              }
            })
          }}
        />
      </div>
    )
  })

  return (
    <>
      <TitleComponent title={qtestName + " Quiz"} isBackReqd={false} style={{marginBottom: "2rem"}} />
      {qtestImage && <img className="nameEmail__image" src={S3URL + "readymade_banner/" + qtestImage} alt={qtestName + " Quiz"} />}
      <div className="nameEmail__details">
        <label style={{ fontWeight: "700" }}>NAME</label>
        <input
          name="name"
          type="text"
          value={name}
          readOnly={isLoggedInUser}
          onChange={handleNameChange}
        />
      </div>
      <div className="nameEmail__details">
        <label style={{ fontWeight: "700" }}>EMAIL</label>
        <input
          name="email"
          type="email"
          value={email}
          readOnly={isLoggedInUser}
          onChange={handleEmailChange}
        />
      </div>
      {additionalFieldsView}
      <div className="nameEmail__button">
        <Tooltip
          title={buttonDisableFlag ? "Kindly enter all fields and provide a valid email" : ""}
          arrow
        >
          <div>
            <Button
              onClick={handleProceedClick}
              disabled={buttonDisableFlag}
              style={{
                ...styledButton,
                backgroundColor: buttonDisableFlag ? "grey" : "#fdb913",
              }}
            >
              PROCEED
            </Button>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

const QtestNotActiveComponent = ({title, message}) => {
  return (
    <>
      <TitleComponent title={title} isBackReqd={false} />
      <p>{message}</p>
    </>
  )
}

export default NameEmailComponent;
