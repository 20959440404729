import "./CreateUserComponent.scss";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import axios from "axios";
import DisplayCardComponent from "../../../shared/displayCardComponent/DisplayCardComponent";
import { BEURL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import Context from "../../../context/Context";
import { RadioBtn } from "../../editProfileComponent/EditProfileComponent";
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent";
import InputComponent from "../../../shared/inputComponent/InputComponent";
import TitleComponent from "../../../shared/titleComponent/TitleComponent";
import { checkTypes, checkValidation } from "../../../utils/inputCheckValidation";

const CreateUserComponent = () => {
  const {
    isLoggedIn,
    userType,
  } = useContext(Context);
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [inputUserType, setInputUserType] = useState("");
  const [usernameChecked, setUsernameChecked] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  
  const navigate = useNavigate();
  useEffect(() => {
    // Redirect to home page if not loggedIn and QShala User
    if(!isLoggedIn || userType !== "QShala_Admin"){
      navigate("/")
    }
  }, [])

  const {
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
  } = useContext(Context);

  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const handleUsernameChange = (e) => {
    if (e.target.value.length >= 5) {
      const url = BEURL + apiEndpoints.checkUsername;
      const data = {
        username: e.target.value,
      };
      setLoadingDisplayFlag(true);
      axios
        .post(url, data)
        .then((res) => {
          setAlert("info", res.data);
          setUserName(e.target.value);
          setUsernameChecked(true);
        })
        .catch((error) => {
          setAlert("error", error.response.data);
          setUserName("");
          setUsernameChecked(false);
        })
        .finally(() => setLoadingDisplayFlag(false));
    } else {
        setUsernameChecked(false);
    }
  };

  const handleGiveAccess = () => {
    if (!usernameChecked) {
      return setAlert("error", "Provide a proper available username!");
    }
    var errorMessage = checkValidation([
      {
        value: userName,
        fieldName: "Username",
        checksFor: [checkTypes.NOTEMPTY, checkTypes.NOOFCHARS],
        charLength: 5,
        charLengthCheckType: "GREATER",
      },
      {
        value: emailId,
        fieldName: "Email",
        checksFor: [checkTypes.NOTEMPTY, checkTypes.EMAIL],
      },
      {
        value: firstName,
        fieldName: "First Name",
        checksFor: [checkTypes.NOTEMPTY],
      },
      {
        value: lastName,
        fieldName: "Last Name",
        checksFor: [checkTypes.NOTEMPTY],
      },
      {
        value: inputUserType,
        fieldName: "User Type",
        checksFor: [checkTypes.NOTEMPTY],
      },
    ]);

    if (errorMessage) {
      return setAlert("error", errorMessage);
    } else {
      const url = BEURL + apiEndpoints.createUser;
      const data = {
        userName,
        firstName,
        lastName,
        emailId,
        userType: inputUserType,
      };
      setLoadingDisplayFlag(true);
      axios
        .post(url, data)
        .then((res) => {
          setAlert("success", "User created successfully!");
          resetCreateUser()
          setUserDetails({
            "Username": res.data.userName,
            "Password": res.data.password,
            "Email": res.data.emailId,
            "UserType": res.data.userType,
          });
        })
        .catch((error) => {
          setAlert("error", error.response.data);
        })
        .finally(() => setLoadingDisplayFlag(false));
    }
  };

  const goToHome = () => {
    navigate("/");
  };

  const resetCreateUser = () => {
    setUserName("")
    setFirstName("")
    setLastName("")
    setEmailId("")
    setInputUserType("")
    setUsernameChecked(false)
  }

  const handleGoBack = () => {
    if(!userDetails){
      goToHome()
    } else{
      resetCreateUser()
      setUserDetails(null)
    }
  }

  return (
      <div className="createUser">
        <TitleComponent
          title={!userDetails?"Create New Users":"New User Details"}
          handleOnClick={handleGoBack}
        />
        {!userDetails ? (<>
          <div className="createUser__input">
            <div className="createUser__inputGrid">
              <InputComponent
                Label="USERNAME"
                Type="text"
                Value={userName}
                OnBlur={handleUsernameChange}
                OnChange={(e) => setUserName(e.target.value)}
              />
              <InputComponent
                Label="FIRST NAME"
                Type="text"
                Value={firstName}
                OnChange={(e) => setFirstName(e.target.value)}
              />
              <InputComponent
                Label="LAST NAME"
                Type="text"
                Value={lastName}
                OnChange={(e) => setLastName(e.target.value)}
              />
              <InputComponent
                Label="EMAIL"
                Type="text"
                Value={emailId}
                OnChange={(e) => setEmailId(e.target.value)}
              />
            </div>
            <div className="createUser__userTypeRadio">
              <label>USER TYPE</label>
              <RadioGroup
                aria-label="user-type"
                name="inputUserType"
                onChange={(e) => setInputUserType(e.target.value)}
                row>
                <RadioBtn label={"Regular User"} value={"REGULAR"} />
                <RadioBtn label={"Premium User"} value={"PREMIUM"} />
              </RadioGroup>
            </div>
          </div>
          <FillButtonComponent
            displayText={"GIVE ACCESS"}
            bgColor="#30B2E7"
            isDisabled={
              !userName || !firstName || !lastName || !emailId || !inputUserType
            }
            handleClick={handleGiveAccess}
          />
         </>) : (
        <DisplayCardComponent 
          data={userDetails}
        />
      )}
      </div>
  );
};

export default CreateUserComponent;
