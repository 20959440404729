import "./CreateMCQComponent.scss";
import React, { useContext, useEffect, useState } from "react";
import SaveordiscardComponent from "../saveordiscardComponent/SaveordiscardComponent";
import BlueInputComponent from "../blueInputComponent/BlueInputComponent";
import MediaUploadComponent from "../mediaUploadComponent/MediaUploadComponent";
import { RadioGroup } from "@mui/material";
import RadioBtnTicked from "../radioBtnTicked/RadioBtnTicked";
import { BEURL, S3URL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import axios from "axios";
import Context from "../../../context/Context";
import QuestionDetailsComponent from "../questionDetailsComponent/QuestionDetailsComponent";
import DiscardSlideComponent from "../discardSlideComponent/DiscardSlideComponent";
import CreateNonQuestionComponent from "../createNonQuestionComponent/CreateNonQuestionComponent";
import { get_answer_blanks_with_hint_letter } from "../../../shared/constants";

//MCQ Blue answer component with options and tick radio button
const MCQAnswer = ({ option, answer, setAnswer, questionType }) => {
  const onChangeAnswer = (e) => {
    setAnswer((prevAnswer) => {
      let updatedAnswer = { ...prevAnswer };
      updatedAnswer[e.target.id] = e.target.value;
      return updatedAnswer;
    });
  };
  return (
    <div>
      <p className="questionTitle">{option}</p>
      <BlueInputComponent
        id={option}
        OnChange={onChangeAnswer}
        Value={answer}
        Maxlength="60"
        disabled={questionType === "TRF"}
      />
      <RadioBtnTicked isDisabled={answer === ""} value={option} />
    </div>
  );
};

const CreateMCQComponent = ({
  questionData,
  quizid,
  setComponentInFocus,
  questionType,
}) => {
  const media_upload_placeholder_image =
    "/images/upload_media_placeholder_image.png";
  //states
  const [question, setQuestion] = useState("");
  const [correctOption, setCorrectOption] = useState("");
  const [questionImageURL, setQuestionImageURL] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [openQuestionDetails, setOpenQuestionDetails] = useState(false);
  const [time, setTime] = useState(30);
  const [points, setPoints] = useState(100);
  const [openDiscardConfirmation, setOpenDiscardConfirmation] = useState(false);
  const [showAnswerSlide, setShowAnswerSlide] = useState(false);
  const [answerImageURL, setAnswerImageURL] = useState("");
  const [answerContent, setAnswerContent] = useState("");
  const [answer, setAnswer] = useState(
    questionType === "MCQ"
      ? { A: "", B: "", C: "", D: "" }
      : { A: "True", B: "False" }
  );
  //when navigated after clicking on the miniSlides
  useEffect(() => {
    if (Object.keys(questionData).length === 0) return; //dont execute if questionData is empty
    questionData.questionTitle && setQuestion(questionData.questionTitle);
    questionData.correctAnswer &&
      setCorrectOption(eval(questionData.correctAnswer)[0]);
    questionData.timeToAnswer && setTime(questionData.timeToAnswer);
    questionData.maxPoints && setPoints(questionData.maxPoints);
    questionData.answerContent && setAnswerContent(questionData.answerContent);
    if (questionData.answerChoices) {
      const answers = eval(questionData.answerChoices);
      const options = ["A", "B", "C", "D"];
      const data = {};
      answers.forEach((answer, idx) => {
        data[options[idx]] = answer;
      });
      setAnswer(data);
      //if answer choices are present then so is the correct answer; so no check reqd
      setCorrectOption(
        options[
          eval(questionData.answerChoices).indexOf(
            eval(questionData.correctAnswer)[0]
          )
        ]
      );
    }
    
    // Set question and answer image properly
    if(questionData.s3ImageName){
      setQuestionImageURL(S3URL + "readymade/" + questionData.s3ImageName);
    }else{
      setQuestionImageURL("")
    }
    if(questionData.s3AnswerImageName){
      setAnswerImageURL(S3URL + "readymade/" + questionData.s3AnswerImageName);
    }else{
      setAnswerImageURL("")
    }

    //show question slide
    setShowAnswerSlide(false);
  }, [questionData]);

  const { setLoadingDisplayFlag, setEditQuestionsList, setEditQuizDetails, setNotificationType, setNotificationMessage, setNotificationDisplayFlag } = useContext(Context);
  
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  }
  
  //Add question
  const saveQuestion = (data, isEdit) => {
    const url = isEdit
      ? BEURL + apiEndpoints.editQuestion
      : BEURL + apiEndpoints.addQuestion;
    axios
      .post(url, JSON.stringify(data))
      .then((res) => {
        let editedQuestions = res.data;
        editedQuestions = editedQuestions.map(question => {
          if (question.questionType === "CNT" || question.questionType === "FIB") {
            question.answerBlanks = get_answer_blanks_with_hint_letter(eval(question.correctAnswer)[0]);
          }
          return question;
        });
        setEditQuestionsList(editedQuestions);
        let newQuestionIdList = editedQuestions.map(question=>question.questionId)
        setEditQuizDetails((prevQuiz) => {
          let updatedQuiz = { ...prevQuiz };
          updatedQuiz.questionIds = newQuestionIdList
          return updatedQuiz
        })
        if (res.status === 200) {
          if (isEdit) {
            setAlert("success", "Question Edited Successfully");
          }
          else {
            setAlert("success", "Question Added Successfully");
          }
        }
      })
      .catch((error) => alert(error.response.data))
      .finally(() => {
        setLoadingDisplayFlag(false);
        if (isEdit) {
          //if question was edited dont go to new slide
          setShowAnswerSlide(false); //show the question slide
          setOpenQuestionDetails(false); //close the question details pop up
        } //if new question was added
        else setComponentInFocus("SelectSlideComponent"); //show new slide
      });
  };

  //Save question
  const handleSaveQuestion = () => {
    setLoadingDisplayFlag(true);
    const data = {
      quizId: quizid,
      questionType: questionType,
      questionTitle: question,
      timer: time,
      questionImage: questionImageURL,
      answerImage: answerImageURL,
      correctAnswer: answer[correctOption],
      answerChoices: Object.values(answer),
      maxPoints: points,
      answerContent: answerContent,
    };
    //Add questionId for edited question
    if (!!questionData.questionId) data.questionId = questionData.questionId;
    //If the question was already present send a edit request
    //Else add new question
    saveQuestion(data, !!questionData.questionId);
  };
  //Add answer content to states
  const handleSubmitAnswer = (imageURL, answerContent) => {
    setAnswerImageURL(imageURL);
    setAnswerContent(answerContent);
    setOpenQuestionDetails(true);
  };
  //Prevent premature form submissions
  useEffect(
    () =>
      setIsSaveDisabled(
        Object.values(answer).indexOf("") !== -1 ||
          correctOption === "" ||
          question === ""
      ),
    [correctOption, answer, question]
  );

  return (
    <>
      <QuestionDetailsComponent
        open={openQuestionDetails}
        handleClose={() => setOpenQuestionDetails(false)}
        handleAddQuestion={handleSaveQuestion}
        editQuestonFlag={!!questionData.questionId} // True if questionId exists
        time={time}
        setTime={setTime}
        points={points}
        setPoints={setPoints}
      />
      <DiscardSlideComponent
        open={openDiscardConfirmation}
        handleClose={() => setOpenDiscardConfirmation(false)}
        handleDiscardSlide={() => setComponentInFocus("SelectSlideComponent")}
      />
      {showAnswerSlide ? (
        <CreateNonQuestionComponent
          questionTitle={question}
          questionImageURL={questionImageURL}
          info={answerContent}
          handleSubmitAnswer={handleSubmitAnswer}
          setComponentInFocus={setComponentInFocus}
        />
      ) : (
        <div className="createMCQ">
          <div className="createMCQ__inner">
            <p className="questionTitle">
              Question({question.length}/300 characters)
            </p>
            <BlueInputComponent
              Value={question}
              OnChange={(e) => setQuestion(e.target.value)}
              Maxlength="300"
            />
            <div className="createMCQ__inner__image">
              <MediaUploadComponent
                placeholderimage={media_upload_placeholder_image}
                imageURL={questionImageURL}
                setImageURL={setQuestionImageURL}
              />
            </div>
            <p className="questionTitle">
              Answers (After filing in the options, select the correct one)
            </p>
            <RadioGroup
              value={correctOption}
              onChange={(e) => setCorrectOption(e.target.value)}
              className="createMCQ__inner__radioGroup"
            >
              <div className="options">
                {Object.keys(answer).map((option) => (
                  <MCQAnswer
                    key={option}
                    option={option}
                    answer={answer[option]}
                    setAnswer={setAnswer}
                    questionType={questionType}
                  />
                ))}
              </div>
            </RadioGroup>
          </div>
          <SaveordiscardComponent
            isSaveDisabled={isSaveDisabled}
            handleSave={() => setShowAnswerSlide(true)}
            handleDiscard={() => setOpenDiscardConfirmation(true)}
          />
        </div>
      )}
    </>
  );
};

export default CreateMCQComponent;
