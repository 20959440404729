import React from 'react'
import "./LeaderboardComponent.scss"
import Drawer from "@mui/material/Drawer";
import { MdOutlineClose } from "react-icons/md";
import { useContext } from "react";
import Context from "./../../context/Context";
import { useLocation } from 'react-router-dom';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LeaderboardList from '../leaderboardList/LeaderboardList';

const drawerWidth = '380px'; // width for leaderboard sidebar

function LeaderboardComponent({ quiz }) {
  const { leaderboardData, playerAnswerCount, playerCorrectAnswerCount, showAnswerPage, 
    leaderBoardOpen, setLeaderBoardOpen, noOfCorrectAns, noOfQuestions, questionData } = useContext(Context);
  const location = useLocation()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
  return (
    <Drawer
      sx={{
        width:drawerWidth,
        display: !isMobile ? "block": "none",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          backgroundColor: "#0097d4",
        },
      }}
      anchor={"right"}
      open={leaderBoardOpen}
      variant="persistent"
    >
      <div className="leaderboard">
        <div className="leaderboard__header">
          <p>Leaderboard {quiz && <span>{
            leaderboardData?.playerStats?.length > 1 ? (
            showAnswerPage? 
              (`${playerCorrectAnswerCount}/${leaderboardData?.noOfActivePlayers} Got it Right!`) :
              (`${playerAnswerCount}/${leaderboardData?.noOfActivePlayers} Answered`)) : 
              (`${noOfCorrectAns}/${questionData?.questionNumber} Correct Answers`)
          }</span>}</p>
          <MdOutlineClose
            size={20}
            style={{ cursor: "pointer", display: location.pathname === "/podium" ? "none" : "block" }}
            onClick={() => {setLeaderBoardOpen(false)}}
          />
        </div>     
        <LeaderboardList />
      </div>
    </Drawer>
  );
}

export default LeaderboardComponent;
