import React, { useContext, useEffect, useState } from "react";
import Context from "../../context/Context"
import { useNavigate } from "react-router-dom";
import "./QuizPreviewComponent.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import QtestQuestionTypeSelectComponent from "../qtestComponent/qtestQuestionTypeSelectComponent/QtestQuestionTypeSelectComponent";
import QtestFooterComponent from "../qtestComponent/qtestFooterComponent/QtestFooterComponent";
import QtestAnswerComponent from "../qtestComponent/qtestAnswerComponent/QtestAnswerComponent";
import QtestMobileFixedHeader from "../qtestComponent/qtestMobileFixedHeader/QtestMobileFixedHeader";
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent";
import {checkArrayEqual} from "../../shared/constants";
import testQuestions from "../../utils/testQuestions";
import AccessPromptComponent from "../createQuizComponents/accessPromptComponent/AccessPromptComponent";

const QuizPreviewComponent = () => {
  const navigate = useNavigate();

  // If redirecting from a component, Add the below line in the calling component and uncomment the linew below it
  // navigate('/previewquiz', {state:{questions: questions}})
  // Uncomment the below lines
  // const { state } = useLocation();
  // if(!state){
  //     return <Navigate replace to="/home" />
  // }
  // const { questions } = state;

  // Load test questions
  // const questions = testQuestions;

  const {editQuestionsList} = useContext(Context)
  const [questions, setQuestions] = useState([]);

  useEffect(()=>{
    if(editQuestionsList.length==0){ // Redirect to home if editQuestionsList context is empty
      navigate("/home")
    }
    else {
      // Prep and save questions from global context
      let contextEditQuestionsList = editQuestionsList
      let questionsCount = contextEditQuestionsList.length
      // Add totalNoOfQuestions, playerScore and playerRunningScore for all questions
      contextEditQuestionsList = contextEditQuestionsList.map((question, index)=>{
        question.questionNumber = index+1
        question.playerScore = 0
        question.playerRunningScore = 0
        question.totalNoOfQuestions = questionsCount
        // question imageName field change for qtest preview
        question.s3ImageName && (question.imageName = question.s3ImageName)
       
        return question
      })
      setQuestions(contextEditQuestionsList)
    }
  },[])

  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showAnswerPage, setShowAnswerPage] = useState(false);
  const [questTimerProgress, setQuestTimerProgress] = useState(0);
  const [questiontimerInterval, setQuestionTimerInterval] = useState(null);

  // For results
  const [rank, setRank] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const resetForNextQuestion = () => {
    setSelectedAnswer("");
    setShowAnswerPage(false);
    setShowResult(false);
    setQuestTimerProgress(0);
    questiontimerInterval && clearInterval(questiontimerInterval);
    setQuestionTimerInterval(null);
  };

  // Run timer after questionData is set
  useEffect(() => {
    if(questions.length!=0){
      if ((questionIndex < questions.length)&&(questions[questionIndex].questionType != "NQS")) {
        const timerInterval = setInterval(() => {
          setQuestTimerProgress((prevProgress) => {
            if (prevProgress >= questions[questionIndex].timeToAnswer) {
              clearInterval(timerInterval);
              dummySubmitAnswer({
                  qtestType: questions[questionIndex].qtestType,
                  questionId: questions[questionIndex].questionId,
                  questionType: questions[questionIndex].questionType,
                  playerAnswer: "",
                  timeTaken: prevProgress,
                });
            }
            return prevProgress + 1;
          });
        }, 1000);
        setQuestionTimerInterval(timerInterval);
      }
    }
    return () => {
      clearInterval(questiontimerInterval);
      setQuestTimerProgress(0);
    };
  }, [questionIndex, questions]);

  const setCurrentQuestionScore = (playerAnswer, playerTimeTaken, questionType) => {
    let correctAnswerList, currentScore;
    const oneAnswerTypes = ["MCQ", "TRF", "CNT", "FIB"];
    if(oneAnswerTypes.includes(questionType)){
        correctAnswerList = eval(questions[questionIndex].correctAnswer).map(element => {
            return element.toLowerCase();
        });
    }
    
    if ((questionType == "MTF" && checkArrayEqual(playerAnswer, questions[questionIndex].correctAnswer[1]) ) || // For MTF comparng answer list in the correct answer
        (questionType == "DND" && checkArrayEqual(playerAnswer, questions[questionIndex].correctAnswer) ) || // For DND
        (oneAnswerTypes.includes(questionType) && correctAnswerList.includes(playerAnswer.toLowerCase()) )){ // for MCQ, TRF, CNT, FIB
            currentScore = Math.round(100 - ((100/questions[questionIndex].timeToAnswer)*playerTimeTaken) + 1)
    }        
    else{
        currentScore = 0
    }
    // For this question subtract previous score and add new score
    let tempRunningScore = questions[questionIndex].playerRunningScore
    tempRunningScore -= questions[questionIndex].playerScore
    tempRunningScore += currentScore
    // Update running score in all questions
    questions.forEach((question)=>{
        question.playerRunningScore = tempRunningScore;
    })
    // Update old score
    questions[questionIndex].playerScore = currentScore 
  }

  // Dummy submit answer
  const dummySubmitAnswer = ({
    qtestType,
    questionId,
    playerAnswer,
    questionType,
    playerTimeTaken = (questTimerProgress>questions[questionIndex].timeToAnswer?questions[questionIndex].timeToAnswer:questTimerProgress),
  }) => {
    if(questionType=="NQS"){
      handleNextQuestion();
    }
    else{
      questiontimerInterval && clearInterval(questiontimerInterval);
      setQuestionTimerInterval(null);
      setCurrentQuestionScore(playerAnswer, playerTimeTaken, questionType)
      setShowAnswerPage(true);
    }
  };

  // Returns a random number between min (inclusive) and max (exclusive)
  function getRandomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  const handlePreviousView = () => {
    if (questionIndex > 0) {
      // Decrement index only for the second element
      resetForNextQuestion(); // Reset before next question
      setQuestionIndex((prevValue) => {
        return prevValue - 1;
      });
    }
  };

  const handleNextView = () => {
    if (questionIndex < questions.length - 1) {
      // Increment index only for the last but one element
      resetForNextQuestion(); // Reset before next question
      setQuestionIndex((prevValue) => {
        return prevValue + 1;
      });
    }
  };

  const handleNextQuestion = () => {
    if (questionIndex < questions.length - 1) {
      // Increment index only for the last but one element
      resetForNextQuestion(); // Reset before next question
      setQuestionIndex((prevValue) => {
        return prevValue + 1;
      });
    }
    // else if (questionIndex == questions.length - 1) {
    //   setResultPage();
    // }
  };

  const setResultPage = () => {
    setRank(Math.ceil(getRandomNumber(1, 11)));
    // Set totalscore as the playerRunningScore of the last question
    setTotalScore(questions[questions.length-1].playerRunningScore);
    setShowAnswerPage(true);
    setShowResult(true);
  };

  useEffect(() => {
    if(document.getElementById("quizPreviewComp_content")){
      document.getElementById("quizPreviewComp_content").scrollTo(0, 0);
    }
  }, [showAnswerPage]);

  // Block back and reload button in browser
  const [finishStatus, setfinishStatus] = useState(false);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to leave the game?")) {
        setfinishStatus(true);
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };
  const onReloadButtonEvent = (e) => {
    e.preventDefault();
    return (e.returnValue =
      "Data will be lost if you leave the page, are you sure?");
  };
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    window.addEventListener("beforeunload", onReloadButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      window.removeEventListener("beforeunload", onReloadButtonEvent);
    };
  }, []);

  return (
    <>
      {(questions.length!=0) && (<div className="quizPreviewComponent">
        <div className="quizPreviewComponent__questionArrows">
          <div
            className="quizPreviewComponent__leftArrow"
            onClick={handlePreviousView}
          >
            <svg
              width="22"
              height="38"
              viewBox="0 0 22 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 35L3 19L19 3"
                stroke={questionIndex <= 0 ? "#888888" : "white"}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="quizPreviewComponent__content"
            id="quizPreviewComp_content"
            style={{
              width: "100vw",
            }}
          >
            {!showAnswerPage && isTablet && questions[questionIndex] && (
              <QtestMobileFixedHeader
                questionCount={questions[questionIndex].questionNumber}
                noOfQuestions={questions[questionIndex].totalNoOfQuestions}
                maxTime={questions[questionIndex].timeToAnswer}
                timerProgress={questTimerProgress}
              />
            )}

            {showAnswerPage ? (
              <QtestAnswerComponent
                answer={questions[questionIndex]}
                selectedAnswer={selectedAnswer}
                showResult={showResult}
                rank={rank}
                totalScore={totalScore}
              />
            ) : (
              questions[questionIndex] && (
                <QtestQuestionTypeSelectComponent
                  question={questions[questionIndex]}
                  setSelectedAnswer={setSelectedAnswer}
                  selectedAnswer={selectedAnswer}
                  questionCount={questions[questionIndex]?.questionNumber} // Change to correct question number
                  noOfQuestions={questions[questionIndex]?.totalNoOfQuestions}
                  submitAnswer={dummySubmitAnswer}
                />
              )
            )}

            {showAnswerPage && !showResult && (
              <div className="quizPreviewComponent__nextBtnFooter">
                <FillButtonComponent
                  displayText={
                    questions[questionIndex].questionNumber ===
                    questions[questionIndex].totalNoOfQuestions
                      ? "End Quiz"
                      : "Next Question"
                  }
                  handleClick={handleNextQuestion}
                />
              </div>
            )}
          </div>
          <div
            className="quizPreviewComponent__rightArrow"
            onClick={handleNextView}
          >
            <svg
              width="22"
              height="38"
              viewBox="0 0 22 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3L19 19L3 35"
                stroke={
                  questionIndex >= questions.length - 1 ? "#888888" : "white"
                }
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {questions[questionIndex] &&
          (isTablet || showAnswerPage || (
            <QtestFooterComponent
              showTimer={questions[questionIndex].questionType != "NQS"}
              maxTime={questions[questionIndex]?.timeToAnswer}
              timerProgress={questTimerProgress}
            />
          ))}
      </div>)}
    </>
  );
};

export default QuizPreviewComponent;
