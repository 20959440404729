import React, { useEffect } from "react";
import "./QtestAnswerComponent.scss";
import { S3URL } from "../../../api/BaseUrl";
import QuizMediaComponent from "../../quizComponent/quizMediaComponent/quizMediaComponent/QuizMediaComponent";
import QuizAnswerMTF from "../../quizComponent/quizAnswerComponent/QuizAnswerMTF";
import QuizArrangeDragArrange from "../../quizComponent/quizAnswerComponent/QuizAnswerDragArrange";

const QtestAnswerSubComponent = ({ answer, title }) => {
  const { correctAnswer, imageName, s3AnswerImageName, answerContent, qtestType, questionType, playerScore, playerRunningScore } =
    answer || {};
  return (
    <div className="qtestanswer">
      <div className="qtestanswer__title">
        <p className="qtestanswer__titleText">{title}</p>
      </div>
      <p className="qtestanswer__curScoreText">You got {playerScore} points</p>
      <div className="qtestanswer__scoreBanner">
            TOTAL POINTS : {playerRunningScore}
      </div>
      
      {questionType != "MTF" && questionType != "DND" && (
        <div
          className="qtestanswer__image"
          style={{marginBottom: "1rem"}}
          // style={{ marginBottom: answerContent == "None" ? "30px" : "0px" }}
        >
          <QuizMediaComponent
            mediaUrl={
              S3URL +
              (qtestType == "IB" ? "generated/" : "readymade/") +
              (s3AnswerImageName?s3AnswerImageName:imageName) // Check for answer image else display question image
            }
          />
        </div>
      )}
      
      {questionType == "MTF" && <QuizAnswerMTF correctAnswer={correctAnswer} />}
      {questionType == "DND" && (
        <QuizArrangeDragArrange correctAnswer={correctAnswer} />
      )}

      {answerContent != "None" && (
        <div
          className={
            questionType == "CNT"
              ? "qtestanswer__info qtestanswer__connect"
              : "qtestanswer__info"
          }
        >
          <p className="qtestanswer__infoText">{answerContent}</p>
        </div>
      )}
    </div>
  );
};

const QtestResultSubComponent = ({ rank, totalScore }) => {
  return (
    <div className="qtestanswer">
      <img className="qtestanswer__resultTopImage" src="/images/qTFriends.png" alt="qTFriends" />
      <div className="qtestanswer__title qtestanswer__resultTitle">
        <p className="qtestanswer__titleText">
          You have successfully completed your QTest!!!
        </p>
      </div>
      <div className="qtestanswer__info qtestanswer__resultBanner">
        <p>Your Total Score: {totalScore}</p>
        <p>Your Rank: {rank}</p>
      </div>
      {/* <img className="qtestanswer__resultBottomImage" src="/images/qTStranger.png" alt="qTStranger" /> */}
    </div>
  );
};

const QtestAnswerComponent = ({
  answer,
  selectedAnswer,
  showResult,
  rank,
  totalScore,
}) => {
  const { correctAnswer, questionType } = answer || {};
  let title;
  let displayAnswer = "'";
  let same = false;

  // Check the answer and form title and displayAnswer only if correctAnswer exists
  if (correctAnswer) {
    if (questionType == "DND" || questionType == "MTF") {
      const correctAnswerList = eval(correctAnswer);
      if (questionType == "MTF") {
        if (
          JSON.stringify(correctAnswerList[1]) == JSON.stringify(selectedAnswer)
        ) {
          same = true;
        }
      } else {
        if (
          JSON.stringify(correctAnswerList) == JSON.stringify(selectedAnswer)
        ) {
          same = true;
        }
      }
      if (same) {
        title = `Good Job! the Correct Answer is`;
      } else {
        title = `Uh-Oh. the Correct Answer is`;
      }
    } else {
      const answers = eval(correctAnswer).map((ans) => ans.toLowerCase());
      displayAnswer = eval(correctAnswer);
      same = answers.includes(selectedAnswer.toLowerCase()) ? true : false;
      if (same) {
        title = `Good Job! '${displayAnswer}' is the Correct Answer!`;
      } else {
        title = `Uh-Oh. '${displayAnswer}' is the Correct Answer!`;
      }
    }
  }

  useEffect(() => {
    document.getElementById("main").scrollTo(0, 0);
  }, []);

  return (
    <>
      {showResult ? (
        <QtestResultSubComponent rank={rank} totalScore={totalScore} />
      ) : (
        <QtestAnswerSubComponent answer={answer} title={title} />
      )}
    </>
  );
};

export default QtestAnswerComponent;
