import React, { useContext, useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { Tooltip } from "@mui/material";
import MiniMCQComponent from "../miniSlideComponents/miniMCQComponent/MiniMCQComponent";
import MiniNonQuestionComponent from "../miniSlideComponents/miniNonQuestionComponent/MiniNonQuestionComponent";
import MiniMTFComponent from "../miniSlideComponents/miniMTFComponent/MiniMTFComponent";
import MiniFIBComponent from "../miniSlideComponents/miniFIBComponent/MiniFIBComponent";
import Context from "../../../context/Context";
import WarningComponent from "../../../shared/warningComponent/WarningComponent";
import { BEURL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import axios from "axios";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./QuizDrawerComponent.scss";
import { EditIcon } from "../../../shared/Icons";
import { shortenText } from "../../../shared/constants";
import QuizSettingsComponent from "../quizSettingsComponent/quizSettingsComponent";
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent";

const drawerWidth = "25%";

const QuizDrawerComponent = ({setComponentInFocus, componentInFocus}) => {
  const {
    dragFlag,
    setDragFlag,
    activeSlides,
    setActiveSlides,
    setQuestionData,
    editQuizDetails,
    setEditQuizDetails,
    editQuestionsList,
    setEditQuestionsList,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
  } = useContext(Context);

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);
  const [deleteQuestionQuizId, setDeleteQuestionQuizId] = useState(null);
  const [quizSettingsOpen, setQuizSettingsOpen] = useState(false);

  const handleDeleteClick = (quizId, questionId) => {
    setDeleteQuestionQuizId(quizId);
    setDeleteQuestionId(questionId);
    setDeleteQuestionModalOpen(true);
  };

  const removeDeleteQuestionInView = () => {
    const removedQuestionIndex = editQuestionsList.findIndex(
      (question) => question.questionId == deleteQuestionId
    );
    editQuestionsList.splice(removedQuestionIndex, 1);
    setComponentInFocus("SelectSlideComponent")
  };

  const handleDeleteQuestion = () => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.deleteQuestion;
    const data = {
      quizId: deleteQuestionQuizId,
      questionId: deleteQuestionId,
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.status == 200) {
          setLoadingDisplayFlag(false);
          setAlert("success", res.data);
          removeDeleteQuestionInView();
        } else {
          setLoadingDisplayFlag(false);
          setAlert("error", res.data);
        }
      })
      .catch((error) => {
        setLoadingDisplayFlag(false);
        setAlert("error", error.response.data);
      });
  };

  const handleQuestionDrop = (result) => {
    setDragFlag(false);
    // Sanity check
    if (result.destination &&
      activeSlides.length > 0 &&
      activeSlides.indexOf(result.draggableId) > -1
    ) {
      const items = Array.from(editQuestionsList);
      const reorderedItems = items.filter((item) => activeSlides.indexOf(item.questionId) > -1); // Question items from the active slide
      const remainingItems = items.filter((item) => activeSlides.indexOf(item.questionId) === -1); // Rest of the question items
      // Drop the items in the destination index
      remainingItems.splice(
        result.destination.index,
        0,
        ...reorderedItems
      );
      setEditQuestionsList(remainingItems);
    }
  };

  const handleEditQuizQuestionOrder = () => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.editQuizDetails;
    const newQuestionIds = editQuestionsList.map(
      (question) => question.questionId,
    );
    let data = {
      quizId: editQuizDetails.quizId,
      questionIds: newQuestionIds,
    };
    axios
      .post(url, JSON.stringify(data))
      .then((res) => {
        if (res.status == 200) {
          setAlert("success", res.data);
          setEditQuizDetails((prevDetails) => ({
            ...prevDetails,
            questionIds: newQuestionIds,
          }));
          // Edit Quiz Details in View
        } else {
          setAlert("error", res.data);
        }
      })
      .catch((error) => alert(error.response.data))
      .finally(() => {
        setLoadingDisplayFlag(false);
      });
  };

  const handleQuestionDragStart = (result) => {
    setDragFlag(true);
  };
  const handleQuestionDragUpdate = (result) => {
    // For future animations to correct multiple slide select
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          display: "flex",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            display: "flex",
            width: drawerWidth,
            background: "#007FB3",
            border: "none",
            paddingLeft: "1rem",
            paddingTop: "13vh",
            maxWidth: "400px",
            alignItems: "center",
            gap: "1rem",
          },
          "& .MuiDrawer-paper::-webkit-scrollbar": {
            width: "7px",
          },
          "& .MuiDrawer-paper::-webkit-scrollbar-track": {
            backgroundColor: "#0097D4",
          },
          "& .MuiDrawer-paper::-webkit-scrollbar-thumb": {
            background: "#30B2E7",
            borderRadius: "7px",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <div className="quizDrawerComponent__header">
          <div className="quizDrawerComponent__header__edit">
            <Tooltip title={editQuizDetails.quizName?editQuizDetails.quizName:""} placement="bottom" arrow>
              <div className="quizDrawerComponent__header__title">
                {editQuizDetails.quizName && shortenText({stringElement: editQuizDetails.quizName, charCount: 20})}
              </div>
            </Tooltip>
            <EditIcon
              className="quizDrawerComponent__header__edit__icon"
              onClick={() => { 
                setQuizSettingsOpen(true)
              }}
            />
          </div>
          <div className="quizDrawerComponent__header__bottom">
            <div className="quizDrawerComponent__header__date">
              {editQuizDetails.editedOn &&
                "last saved on: " + editQuizDetails.editedOn}
            </div>
            {editQuestionsList.length > 0 && JSON.stringify(editQuestionsList.map(question => question.questionId)) !== JSON.stringify(editQuizDetails.questionIds) && (
              <button
                onClick={handleEditQuizQuestionOrder}
                className="quizDrawerComponent__header__button">
                Save Order
              </button>
            )}
          </div>
        </div>
        <div className="quizDrawerComponent__questionsList" style={{backgroundColor: editQuestionsList.length?"#75b543":"transparent"}}>
          <DragDropContext
            onDragEnd={handleQuestionDrop}
            onDragStart={handleQuestionDragStart}
            onDragUpdate={handleQuestionDragUpdate}
          >
            <Droppable droppableId="createQuizDrawer">
              {(provided) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {editQuestionsList.map(
                      (question, index) =>
                        // During drag and more than 1 active slide exists and current question id is in active slides, display only first slide in active slide
                        (!dragFlag ||
                          activeSlides.length == 1 ||
                          !activeSlides.includes(question.questionId) ||
                          activeSlides[0] == question.questionId) && ( // Applied Demorgan's law for a "Display On Condition"
                          <Draggable
                            key={question.questionId}
                            index={index}
                            draggableId={String(question.questionId)}
                          >
                            {(provided) => {
                              return (
                                <div
                                  className="quizDrawerComponent__questionsList__question"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="quizDrawerComponent__questionsList__question__index">
                                    {index + 1}
                                  </div>
                                  {question.questionType === "MCQ" ? (
                                    <MiniMCQComponent
                                      questionData={question}
                                      key={question.questionId}
                                      handleDeleteClick={handleDeleteClick}
                                      questionType="MCQ"
                                      correctOption={
                                        ["A", "B", "C", "D"][
                                          eval(question.answerChoices).indexOf(
                                            eval(question.correctAnswer)[0]
                                          )
                                        ]
                                      }
                                    />
                                  ) : question.questionType === "TRF" ? (
                                    <MiniMCQComponent
                                      questionData={question}
                                      key={question.questionId}
                                      handleDeleteClick={handleDeleteClick}
                                      questionType="TRF"
                                      correctOption={
                                        ["A", "B"][
                                          eval(question.answerChoices).indexOf(
                                            eval(question.correctAnswer)[0]
                                          )
                                        ]
                                      }
                                    />
                                  ) : question.questionType === "MTF" ||
                                    question.questionType === "DND" ? (
                                    <MiniMTFComponent
                                      questionData={question}
                                      key={question.questionId}
                                      handleDeleteClick={handleDeleteClick}
                                    />
                                  ) : question.questionType === "CNT" ||
                                    question.questionType === "FIB" ? (
                                    <MiniFIBComponent
                                      questionData={question}
                                      key={question.questionId}
                                      handleDeleteClick={handleDeleteClick}
                                    />
                                  ) : question.questionType === "NQS" ? (
                                    <MiniNonQuestionComponent
                                      questionData={question}
                                      key={question.questionId}
                                      handleDeleteClick={handleDeleteClick}
                                    />
                                  ) : (
                                    <MiniMCQComponent
                                      questionType="MCQ"
                                      handleDeleteClick={handleDeleteClick}
                                    />
                                  )}
                                </div>
                              );
                            }}
                          </Draggable>
                        )
                    )}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
        <FillButtonComponent 
          displayText={"Add Question"} 
          isDisabled={!editQuestionsList.length || componentInFocus === "SelectSlideComponent"}
          handleClick={() => {
            setActiveSlides([])
            setQuestionData({})
          }}
          style={{
            height: "3rem",
            marginBottom: "1rem",
            paddingTop: "0.2rem",
            fontSize: "x-large",
            backgroundColor: (!editQuestionsList.length || componentInFocus === "SelectSlideComponent") ? "#C1C1C1" : "#FDB913"
          }}
        />
      </Drawer>
      <QuizSettingsComponent
        isOpen={quizSettingsOpen}
        handleCloseModel={() => { 
          setQuizSettingsOpen(false)
        }}
      />
      <WarningComponent
        buttonText="Confirm"
        modalOpen={deleteQuestionModalOpen}
        setModalOpen={setDeleteQuestionModalOpen}
        handleClick={handleDeleteQuestion}
      >
        <h1>
          Are you sure you want to
          <br />
          Delete this Slide?
        </h1>
        <p>
          <span style={{ fontWeight: 600, color: "red", fontStyle: "italic" }}>
            This action is irreversible.
          </span>{" "}
          Click on the button below if you wish to proceed.
        </p>
      </WarningComponent>
    </>
  );
};

export default QuizDrawerComponent;
