import "../createMCQComponent/CreateMCQComponent.scss";

import React, { useContext, useEffect, useState } from "react";
import BlueInputComponent, {
  BlueTextAreaComponent,
} from "../blueInputComponent/BlueInputComponent";
import MediaUploadComponent from "../mediaUploadComponent/MediaUploadComponent";
import SaveordiscardComponent from "../saveordiscardComponent/SaveordiscardComponent";
import DiscardSlideComponent from "../discardSlideComponent/DiscardSlideComponent";
import { BEURL, S3URL } from "../../../api/BaseUrl";
import Context from "../../../context/Context";
import apiEndpoints from "../../../api/EndPoints";
import axios from "axios";
import { get_answer_blanks_with_hint_letter } from "../../../shared/constants";

const CreateNonQuestionComponent = ({
  questionData = "",
  questionTitle = "",
  questionImageURL = "",
  info = "",
  quizid = "",
  setComponentInFocus = "",
  handleSubmitAnswer = false,
}) => {
  const [answerContent, setAnswerContent] = useState(info);
  const media_upload_placeholder_image =
    "/images/upload_media_placeholder_image.png";
  const [imageURL, setImageURL] = useState(questionImageURL);
  const [title, setTitle] = useState(questionTitle);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [openDiscardConfirmation, setOpenDiscardConfirmation] = useState(false);
  useEffect(() => {
    setIsSaveDisabled(title === "");
  }, [title, answerContent]);

  //when navigated after clicking on the miniSlides
  useEffect(() => {
    if (Object.keys(questionData).length === 0) return; //dont execute if questionData is empty
    questionData.questionTitle && setTitle(questionData.questionTitle);
    questionData.answerChoices &&
      setAnswerContent(eval(questionData.answerChoices)[0]);
    questionData.s3ImageName &&
      setImageURL(S3URL + "readymade/" + questionData.s3ImageName);
  }, [questionData]);

  const { setLoadingDisplayFlag, setEditQuestionsList, setNotificationType, setNotificationMessage, setNotificationDisplayFlag } = useContext(Context);
  
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  }

  //Add question
  const saveQuestion = (data, isEdit) => {
    const url = isEdit
      ? BEURL + apiEndpoints.editQuestion
      : BEURL + apiEndpoints.addQuestion;
    axios
      .post(url, JSON.stringify(data))
      .then((res) => {
        let editedQuestions = res.data;
        editedQuestions = editedQuestions.map(question => {
          if (question.questionType === "CNT" || question.questionType === "FIB") {
            question.answerBlanks = get_answer_blanks_with_hint_letter(eval(question.correctAnswer)[0]);
          }
          return question;
        });
        setEditQuestionsList(editedQuestions);
        if (res.status === 200) {
          if (isEdit) {
            setAlert("success", "Slide Edited Successfully");
          }
          else {
            setAlert("success", "Slide Added Successfully");
          }
        }
      })
      .catch((error) => alert(error.response.data))
      .finally(() => {
        setLoadingDisplayFlag(false);
        if (!isEdit)
          //if question was not edited i.e added then go to new slide
          setComponentInFocus("SelectSlideComponent"); //show new slide
      });
  };

  const handleSave = () => {
    if (handleSubmitAnswer === false) {
      //add non question slide
      setLoadingDisplayFlag(true);
      const data = {
        quizId: quizid,
        questionType: "NQS",
        questionTitle: title,
        questionImage: imageURL,
        answerChoices: [answerContent],
        timer: 5, //default
        maxPoints: 0, //default
      };
      //Add questionId for edited question
      if (!!questionData.questionId) data.questionId = questionData.questionId;
      //If the question was already present send a edit request
      //Else add new question
      saveQuestion(data, !!questionData.questionId);
    } else {
      handleSubmitAnswer(imageURL, answerContent);
    }
  };

  return (
    <>
      <DiscardSlideComponent
        open={openDiscardConfirmation}
        handleClose={() => setOpenDiscardConfirmation(false)}
        handleDiscardSlide={() => setComponentInFocus("SelectSlideComponent")}
      />

      <div className="createMCQ">
        <div className="createMCQ__inner">
          <p className="questionTitle">Title({title.length}/300 characters)</p>
          <BlueInputComponent
            Value={title}
            OnChange={(e) => setTitle(e.target.value)}
            Maxlength="300"
            disabled={!!questionTitle}
          />
          <div className="createMCQ__inner__image">
            <MediaUploadComponent
              placeholderimage={media_upload_placeholder_image}
              imageURL={imageURL}
              setImageURL={setImageURL}
            />
          </div>
          <p className="questionTitle">
            Explanation({answerContent.length}/300 characters)
          </p>
          <BlueTextAreaComponent
            Value={answerContent}
            OnChange={(e) => setAnswerContent(e.target.value)}
            Maxlength="300"
          />
        </div>
        <SaveordiscardComponent
          isSaveDisabled={isSaveDisabled}
          handleSave={handleSave}
          handleDiscard={() => setOpenDiscardConfirmation(true)}
        />
      </div>
    </>
  );
};

export default CreateNonQuestionComponent;
