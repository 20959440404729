import React, { useEffect, useState } from "react";
import CustomModalComponent from "../../../shared/customModalComponent/CustomModalComponent";
import { Button, Slider, Switch, TextField, Tooltip } from "@mui/material";
import "./ScheduleQtestComponent.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { capitalizeSentence } from "../../../shared/constants";

const ScheduleQtestComponent = ({
  modalOpen,
  setModalOpen,
  handleClick,
  showAdditionalFieldsInput=true,
  title="Schedule QTest",
  btnText="Create QTEST",
  initStartDate=null,
  initEndDate=null
}) => {
  dayjs.extend(utc);
  const [startDate, setStartDate] = useState(initStartDate?dayjs(initStartDate):dayjs()); // default to current date and time
  const [endDate, setEndDate] = useState(initEndDate?dayjs(initEndDate).add(7, "day"):dayjs().add(7, "day")); // default to one week from start date
  const [fieldNames, setFieldNames] = useState([])
  const [disableCreateButton, setdisableCreateButton] = useState(true);
  const [endDateError, setEndDateError] = useState(
    "Select Start and End Dates"
  );
  
  const dateStyle = {
    fontFamily: "CaustenR",
    fontSize: "1.2rem",
    height: "2.81rem",
    color: "#FFFFFF",
    backgroundColor:"#007FB3",
    '@media screen and (max-width:900px)': {
      fontSize: '1rem',
    }, 
    '@media screen and (max-width:600px)': {
      fontSize: '0.9rem',
    },  
    "& .MuiSvgIcon-root": { 
      color: "white" 
    },
    "& .MuiButtonBase-root": {
      marginRight: "0px"
    }                      
  }
  
  useEffect(() => {
    checkDates();
  }, [startDate, endDate]);

  const checkDates = () => {
    if (endDate.diff(startDate) <= 0) {
      // Check if end date is greater than start date and time
      setEndDateError("End Date must be greater than Start Date and Time.");
      setdisableCreateButton(true);
      return false;
    } else if (endDate.diff(dayjs()) <= 0) {
      // Check if end date is greater than current date and time
      setEndDateError("End Date must be greater than Current Date and Time.");
      setdisableCreateButton(true);
      return false;
    } else {
      setEndDateError("");
      setdisableCreateButton(false);
      return true;
    }
  };

  const handleStartDateChange = (newDateTime) => {
    setStartDate(newDateTime);
  };

  const handleEndDateChange = (newDateTime) => {
    setEndDate(newDateTime);
  };

  const handleFieldNamesChange = (event) => {
    // Split the input string by commas and remove white spaces and replace with underscores in each element
    let fieldNamesArray = event.target.value.split(',').map((item) => {
      let curItem = capitalizeSentence(item.trim());
      // return curItem.replace(/\s+/g, '_');
      return curItem
    }).filter((strItem)=>{
      if(strItem){
        return strItem
      }
    });
    // Remove duplicates
    let uniqueFieldNames = [...new Set(fieldNamesArray)];
    setFieldNames(uniqueFieldNames);
  };

  const handleBtnClick = () => {
    if (checkDates()) {
      setModalOpen(false)
      handleClick(startDate.utc().format(), endDate.utc().format(), fieldNames);
    }
  };

  return (
    <CustomModalComponent
      open={modalOpen}
      handleClose={() => setModalOpen(false)}
      bgColor="#0097D4"
    >
      <div className="scheduleQtest__modal">
        <h1>{title}</h1>
        <div className="scheduleQtest__details">
          <label className="scheduleQtest__details__label">Start Date</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              InputProps={{ sx: dateStyle }}
              value={startDate}
              inputFormat="DD MMM YYYY HH:mm:ss"
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <TextField
                  className="scheduleQtest__dateTimeTextField"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <label className="scheduleQtest__details__label">
            End Date
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              InputProps={{ sx: dateStyle }}
              value={endDate}
              minDateTime={dayjs()}
              inputFormat="DD MMM YYYY HH:mm:ss"
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <TextField
                  className="scheduleQtest__dateTimeTextField"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <p style={{display: endDateError? "block":"none"}}>{endDateError}</p>

        {/* Host Control Additional Fields */}
        {showAdditionalFieldsInput && (<div className="hostControl">
          <p className="hostControl__label" sx={{
            width: "20%",
          }}>
            Field Names <span style={{fontSize: "small"}}>(Comma Separated)</span>
          </p>
          <Tooltip title="Add field names you want to collect from the user separated by commas. Field names are case sensitive." placement="top" arrow>
            <TextField 
              label="Additional Fields" 
              variant="filled" 
              placeholder="Ex: Team_Name,User_ID,Address etc.."
              onChange={handleFieldNamesChange}
              sx={{
                "& .MuiFormControl-root": {
                  width: "100%",
                },
                "& .MuiFilledInput-root": {
                  backgroundColor: "#007fb3",
                  color: "#FFF",
                  fontFamily: "CaustenR",
                  fontSize: "medium",
                  "@media only screen and (max-width: 600px)": {
                    fontSize: "small",
                  }
                },
                "& .MuiFilledInput-root.Mui-focused": {
                  backgroundColor: "rgb(232, 241, 250)",
                  color: "#000",
                },
                "& .MuiFilledInput-root:hover": {
                  backgroundColor: "#E0E8FF",
                  color: "#000",
                  // Reset on touch devices, it doesn't add specificity
                  "@media (hover: none)": {
                    backgroundColor: "#007fb3",
                    color: "#FFF",
                  }
                },
                "& .MuiFormLabel-root": {
                  fontFamily: "CaustenR",
                  fontSize: "smaller",
                  color: "lightgrey",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#888888"
                },
              }}
            />
          </Tooltip>
        </div>)}

        {showAdditionalFieldsInput && (fieldNames.length?
          <p style={{color: "#FFF"}}>
            Additionaly the fields <span style={{fontWeight: 600, color: "#ECA904"}}>{fieldNames.join(', ')}</span> will be requested from the user at the start of the qtest.
          </p>:
          <p style={{color: "#FFF"}}>
            Email and player name alone will be requested while attending the qtest.
          </p>)}
        
        <Tooltip title={endDateError} placement="top" arrow>
          <span>
            <Button
              variant="contained"
              onClick={handleBtnClick}
              disabled={disableCreateButton}
              style={{ backgroundColor: "#FDB913" }}
              className="scheduleQtest__btn"
            >
              {btnText}
            </Button>
          </span>
        </Tooltip>
      </div>
    </CustomModalComponent>
  );
};

export default ScheduleQtestComponent;
